<template>
	<div class="login-wrap">
		<!-- 左 -->
		<div class="left-wrap">
			<div class="title">登录/注册 Connect Wallet</div>
			<div class="wallet-list-wrap">
				<a-spin :spinning="spinning">

					<div class="wallet-item" @click="connectWallet">
						<img src="../../assets/images/metamask.png" alt="" srcset="">
						<span>MetaMask</span>
					</div>
				</a-spin>

			</div>
			<a-checkbox v-model:checked="checkboxValue" class="checkbox-wrap">
				我已阅读并接受X Sum平台的<span class="label-a" @click="yszcVisible = true">隐私政策</span>
			</a-checkbox>
			<span class="tip">由于登录需要MetaMask钱包签名,避免被黑客攻击风险，建议使用新钱包登录使用</span>
			<!-- <a-checkbox v-model:checked="checkboxValue" class="checkbox-wrap">
				我已阅读并接受TwiSum平台的<span class="label-a">服务协议</span>和<span class="label-a"
					@click="yszcVisible = true">隐私政策</span>
			</a-checkbox> -->
		</div>
		<!-- 右 -->
		<div class="right-wrap">
			<div class="title">
				X Sum，专为你定制的Web3资讯流看板
			</div>
			<div class="list">
				<div class="item">
					<div class="icon"><a-icon type="check-circle" /></div>
					<span>自动化总结关键时段信息，助你轻松掌握Smart Money的动态</span>
				</div>
				<div class="item">
					<div class="icon"><a-icon type="check-circle" /></div>
					<span>分类展示监控的推特用户发言，优化信息流，提升阅读效率</span>
				</div>
				<div class="item">
					<div class="icon"><a-icon type="check-circle" /></div>
					<span>可自定义AI提示词，打造符合个人偏好的专属资讯看板</span>
				</div>
				<div class="item">
					<div class="icon"><a-icon type="check-circle" /></div>
					<span>实时推送监控推特动态，抢占信息前沿 - 即将上线</span>
				</div>
			</div>
		</div>
		<!--  -->
		<a-modal v-model="yszcVisible" title="隐私政策" :footer="null" width="800px">
			<div class="yszc-wrap">
				<div class="title">1. 数据来源与责任声明</div>
				<div class="content">
					本网站（或工具）所展示的内容来源于第三方平台（如推特）。本网站仅为用户提供一个展示推特公开内容的聚合平台，并通过人工智能技术进行总结和分析。所有内容均由推特用户发布，内容的准确性、完整性和合法性由其发布者负责。本网站对其内容不做任何修改、审查或背书，对任何因使用本网站展示内容而产生的法律、责任或后果不承担任何责任。
				</div>
				<div class="title"> 2. 自动化生成的内容
				</div>
				<div class="content"> 本网站的某些信息由人工智能模型（如
					ChatGPT）自动生成或总结，该生成内容基于推特数据输入，可能存在理解偏差或总结错误。我们不对人工智能生成的任何信息或其后果承担责任。用户应自行判断生成内容的准确性、合法性和适用性。本网站提供的服务仅供参考，任何依赖本网站内容的行为均由用户自行承担风险。
				</div>
				<div class="title"> 3. 数据隐私与合规性
				</div>
				<div class="content">
					本网站通过合法途径获取公开数据，不会收集、存储或使用任何用户的私密信息。本网站不对任何未经授权的用户个人信息泄露承担责任。用户应理解，在使用本网站服务时，有可能访问来自境外的内容，境外内容可能不符合中国大陆的法律法规。用户在使用本网站时应遵守相关法律和规定。
				</div>
				<div class="title"> 4. 内容使用限制
				</div>
				<div class="content">
					本网站严禁用户在使用过程中采集、传播、或展示任何涉及政治敏感、色情、不当言论或违反中国大陆法律法规的内容。如果用户被发现从事此类行为，本网站保留立即封禁其账号、停止服务以及采取进一步措施的权利。用户需对其使用本网站过程中产生的内容负全部责任，并遵守国家相关法律及平台的使用规范。
				</div>
				<div class="title"> 5. 外部链接和第三方服务
				</div>
				<div class="content"> 本网站可能包含通向第三方网站（如推特）的链接或服务。本网站对这些第三方内容、隐私政策或其他服务条款不承担任何责任，用户使用此类链接和服务所产生的任何后果应自行承担。
				</div>
				<div class="title"> 6. 使用风险
				</div>
				<div class="content">
					用户在使用本网站时，应遵守当地的法律法规。由于涉及境外平台的数据展示，本网站不对任何因数据或服务限制、审查、网络屏蔽等外部原因导致的无法访问、使用中断或内容不可用承担责任。用户应自行确保通过合规的途径访问本网站及其相关内容。
				</div>
				<div class="title"> 7. 责任免除
				</div>
				<div class="content">
					在法律允许的最大范围内，本网站及其运营者不对任何直接、间接、附带、特殊、后果性或惩罚性损害承担责任，无论这些损害是否与本网站的使用或内容相关，或因使用本网站的内容、服务或工具所引起。
				</div>
				<div class="title"> 8. 修改与更新
				</div>
				<div class="content"> 本免责声明可能随时更新。我们保留在不另行通知的情况下随时修改、更新或删除此免责声明的权利。建议用户定期查看本页内容，以确保知晓任何变更。
				</div>
			</div>

		</a-modal>
	</div>
</template>

<script>
import Web3 from 'web3';
import { getNonce, loginVerify } from "@/api/login.js";
export default ({
	data() {
		return {
			web3: null,
			account: null, // 用户的钱包地址
			checkboxValue: false,
			visible: false,
			yszcVisible: false,
			spinning: false
		}
	},
	methods: {
		async connectWallet() {
			if (!this.checkboxValue) {
				this.$message.warning({ content: '请勾选并阅读隐私协议', duration: 2 });
				return
			}
			this.spinning = true;
			// 检测 MetaMask 是否安装
			if (typeof window.ethereum !== 'undefined') {
				try {
					// 请求连接钱包
					await window.ethereum.request({ method: 'eth_requestAccounts' });
					// 创建 Web3 实例
					this.web3 = new Web3(window.ethereum); // 使用 window.ethereum 连接 MetaMask

					// 获取钱包地址
					const accounts = await this.web3.eth.getAccounts();
					this.account = accounts[0]; // 使用第一个钱包地址

					// 生成随机 nonce

					let nonceRes = await getNonce();
					let nonce = nonceRes.data.data.nonce;


					// 提示用户对 nonce 进行签名
					const signature = await this.web3.eth.personal.sign(
						`Nonce: ${nonce}`,
						this.account
					);
					// 将钱包地址和签名发送到后端进行验证
					await this.sendLoginRequest(this.account, signature, nonce);
				} catch (error) {
					if (error.message.indexOf('Error: User rejected the request') != -1) {
						this.$message.error('登录失败，您已拒绝签名请求！');
					}
					console.error('连接钱包失败:', error);

					this.spinning = false;
				}
			} else {
				// 如果 MetaMask 未安装，提示用户安装
				this.$message.error('请安装 MetaMask 插件');

				this.spinning = false;
			}
		},
		async sendLoginRequest(walletAddress, signature, nonce) {
			try {
				const loginRes = await loginVerify({
					walletAddress,
					signature,
					nonce,
				});
				let loginData = loginRes.data.data;
				localStorage.setItem('token', loginData.token);
				localStorage.setItem('user_info', JSON.stringify(loginData.user));
				this.$message.success({ content: '登录成功', duration: 2 });
				this.spinning = false;
				setTimeout(() => {
					this.$router.push({ path: '/home' });
				}, 2000);
			} catch (error) {
				this.spinning = false;
				// console.error('登录请求失败:', error);
				// this.$message.error('登录请求失败');
			}
		},
		// gotoRegister() {
		// 	this.$router.push({ name: 'Register' });
		// },
		// handleSubmit(e) {
		// 	this.$message.loading({ content: '登录中...', key });
		// 	e.preventDefault();
		// 	this.form.validateFields((err, values) => {
		// 		if (!err) {
		// 			login(values).then(res => {
		// 				let data = res.data.data;
		// 				localStorage.setItem('token', data.token);
		// 				localStorage.setItem('user_info', JSON.stringify(data.user));
		// 				this.$message.success({ content: '登录成功', key, duration: 2 });
		// 				setTimeout(() => {
		// 					this.$router.push({ name: 'Home' });
		// 				}, 2000);
		// 			}).catch(err => {
		// 				this.$message.error({ content: '保存失败！' + err, key, duration: 2 });
		// 			})
		// 		}
		// 	});
		// },
	},
})
</script>

<style lang="scss">
body {
	background-image: url(../../assets/images/sign_bg@2x.png);
	background-size: 100% 100%;
}

.layout-default .ant-layout-content {
	padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.login-wrap {
	display: flex;
    height: 100vh;

	.left-wrap {
		// width: 500px;
		// height: 100vh;
		flex: 1;
		background: #fff;
		padding: 40px;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		margin-top: -200px;
		.tip{
			font-size: 12px;
			color: #999;
			margin-top: 10px;
		}
		.title {
			font-size: 20px;
			font-weight: 600;
		}

		.wallet-list-wrap {
			padding-top: 40px;

			.wallet-item {
				width: 300px;
				padding: 0 20px;
				display: flex;
				align-items: center;
				// justify-content: center;
				margin-bottom: 20px;
				background-color: rgba(0, 0, 0, 0.05);
				height: 70px;
				border-radius: 20px;
				cursor: pointer;

				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
				}

				img {
					width: 50px;
					height: 50px;
					border-radius: 10px;
					margin-right: 20px;
				}

				span {
					font-size: 16px;
					color: #111;
					font-weight: 600;
				}
			}
		}
	}

	.right-wrap {
		flex: 2;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		margin-top: -200px;

		.title {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 50px;
		}

		.list {
			.item {
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				.icon {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: rgba(0, 0, 0, 0.1);
					margin-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;

					.anticon-check-circle {
						font-size: 18px;
					}
				}

			}
		}
	}
}

.checkbox-wrap {
	color: #333 !important;
	font-weight: 400 !important;
}

.label-a {
	color: #1890FF;
	font-weight: 500 !important;

}

.yszc-wrap {
	.title {
		font-size: 14px;
		font-weight: 600;
		color: #111;
		line-height: 30px;
	}

	.content {

		color: #333;
	}
}
</style>